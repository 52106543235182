import { graphql, navigate, useStaticQuery } from "gatsby"
import * as React from "react"

const AppPost = ({ slug, location }) => {
  console.log("Entrosss")
  let data = useStaticQuery(graphql`
    query AppPostBySlug {
      allAppMarkdownRemark {
        nodes {
          excerpt
          html
          fields {
            slug
          }
          frontmatter {
            title
            date(formatString: "MMMM DD, YYYY")
            description
          }
        }
      }
    }
  `)

  let pathname =
    location.pathname.slice(-1) === "/"
      ? location.pathname
      : location.pathname + "/"
  let post = data.allAppMarkdownRemark.nodes.find(
    node => node.fields.slug.toUpperCase() === pathname.toUpperCase()
  )
  if (!post) {
    navigate("/")
  } else {
    return (
      <>
        <article
          className="blog-post"
          itemScope
          itemType="http://schema.org/Article"
        >
          <header>
            <h1 itemProp="headline">{post.frontmatter.title}</h1>
            {/* <p>{post.frontmatter.date}</p> */}
          </header>
          <section
            dangerouslySetInnerHTML={{ __html: post.html }}
            itemProp="articleBody"
          />
          <hr />
        </article>
        <nav className="blog-post-nav"></nav>
      </>
    )
  }
}

export default AppPost
