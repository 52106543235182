import React, { useState } from "react"
import { navigate } from "gatsby"
import Cookies from "js-cookie"
import validateToken from "../services/validarte-token"
const PrivateRoute = ({ component: Component, location, ...rest }) => {
  const [loading, setLoading] = useState()
  const react = Cookies.get()
  let reactCookies = false
  let cookie = {}

  Object.entries(react).forEach(([key, value]) => {
    if (key.includes("tkreact")) {
      if (!reactCookies) {
        cookie = Cookies.get(key)
        reactCookies = true
      }
    }
  })


  if (!reactCookies) {
    navigate("/")
    return null
  }
  validateToken(cookie).then(res => {
    setLoading(res)
  })

  if (loading !== undefined) {
    if (loading) {
      let obj = {
        ...rest,
        location,
      }
      return <Component {...obj} />
    } else {
      navigate("/")
    }
  } else {
    return <h1>Validando token...</h1>
  }
}

export default PrivateRoute
