import React from "react"
import { Router } from "@reach/router"

import Layout from "../../components/layout"
import PrivateRoute from "../../components/PrivateRoute"
import AppPosts from "../../components/app-posts"
import AppPost from "../../components/app-post"

const App = ({ location }) => {
  return (
    <Layout location={location} title="">
      <Router basePath="/app">
        {location.pathname !== "/app/[...]/" && (
          <PrivateRoute exact path="/*" component={AppPost} />
        )}
        <PrivateRoute exact path="/" component={AppPosts} />
      </Router>
    </Layout>
  )
}

export default App
